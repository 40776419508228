<template>
  <v-card-text>
    <p class="text-2xl font-weight-semibold text--primary mb-2 mt-4">
      Buat Password untuk verifikasi akun anda
    </p>
    <p class="mb-8">
      Buat password untuk akun mu.
    </p>
    <v-form
      ref="registerTwoForm"
      class="mt-4"
      @submit.prevent="next"
    >
      <v-text-field
        v-model="inputData.password"
        outlined
        :type="isPasswordVisible ? 'text' : 'password'"
        label="Password"
        placeholder="Password"
        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        hide-details="auto"
        class="mb-4"
        :rules="[ required, lengthValidator(inputData.password, 8) ]"
        @click:append="isPasswordVisible = !isPasswordVisible"
      />
      <v-text-field
        v-model="inputData.confirmPassword"
        outlined
        :type="isPasswordConfirmVisible ? 'text' : 'password'"
        label="Konfirmasi Password"
        placeholder="Konfirmasi Password"
        :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        hide-details="auto"
        class="mb-4"
        :rules="[
          required,
          confirmedValidator(inputData.confirmPassword, inputData.password),
          lengthValidator(inputData.confirmPassword, 8),
        ]"
        @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
      />
      <v-btn
        block
        color="primary"
        type="submit"
        class="mt-6"
        :loading="loading"
      >
        Simpan
      </v-btn>
    </v-form>
  </v-card-text>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { mdiArrowLeft, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { required, confirmedValidator, lengthValidator } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: Object,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const registerTwoForm = ref()
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    const next = () => {
      if (registerTwoForm.value.validate()) {
        emit('next')
      }
    }

    return {
      registerTwoForm,
      inputData,
      isPasswordVisible,
      isPasswordConfirmVisible,

      next,

      required,
      confirmedValidator,
      lengthValidator,

      icons: {
        mdiArrowLeft,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style>

</style>
