<template>
  <div class="d-flex h-100 justify-center align-center">
    <div class="workspace">
      <XyzTransition
        appear
        xyz="fade left-3 delay-3"
      >
        <v-card
          class="pa-8"
          min-width="50vh"
          :loading="loadingDetailUser"
        >
          <div
            v-if="!loadingDetailUser"
            class="d-flex flex-column justify-space-between"
          >
            <XyzTransitionGroup
              appear
              xyz="fade left-3 delay-3"
            >
              <v-img
                :key="1"
                :src="require('@/assets/images/svg/oriens-text.svg')"
                width="120px"
                alt="logo"
                contain
                eager
                class="app-logo mt-2"
              />
              <v-card-text
                :key="2"
                class="mt-4 text-h5"
              >
                Hai, {{ userDetail && userDetail.first_name }}
              </v-card-text>
              <register-two
                :key="3"
                v-model="formData"
                :loading="loadingVerifyUser"
                @next="createPassword"
              />
            </XyzTransitionGroup>
          </div>
        </v-card>
      </XyzTransition>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import RegisterTwo from './components/RegisterTwo.vue'
import useUser from '@/composables/useUser'

export default {
  components: {
    RegisterTwo,
  },
  props: {
    query: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      verifyUser, loadingVerifyUser,
      fetchUserByVerificationCode, loadingDetailUser, userDetail,
    } = useUser()
    const formData = ref({})

    onMounted(() => {
      fetchUserByVerificationCode(props.query.code).catch(() => {
        router.push({ name: 'error-404' })
      })
    })

    const loading = computed(() => store.state.auth.loadingLogin)

    const createPassword = () => {
      verifyUser(props.query.code, formData.value.password, formData.value.confirmPassword).then(() => {
        router.push({ name: 'login' })
      })
    }

    return {
      loading,

      formData,
      createPassword,
      loadingVerifyUser,
      loadingDetailUser,
      userDetail,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
