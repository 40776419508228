import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2 mt-4"},[_vm._v(" Buat Password untuk verifikasi akun anda ")]),_c('p',{staticClass:"mb-8"},[_vm._v(" Buat password untuk akun mu. ")]),_c(VForm,{ref:"registerTwoForm",staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-4",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","placeholder":"Password","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto","rules":[ _vm.required, _vm.lengthValidator(_vm.inputData.password, 8) ]},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.inputData.password),callback:function ($$v) {_vm.$set(_vm.inputData, "password", $$v)},expression:"inputData.password"}}),_c(VTextField,{staticClass:"mb-4",attrs:{"outlined":"","type":_vm.isPasswordConfirmVisible ? 'text' : 'password',"label":"Konfirmasi Password","placeholder":"Konfirmasi Password","append-icon":_vm.isPasswordConfirmVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto","rules":[
        _vm.required,
        _vm.confirmedValidator(_vm.inputData.confirmPassword, _vm.inputData.password),
        _vm.lengthValidator(_vm.inputData.confirmPassword, 8) ]},on:{"click:append":function($event){_vm.isPasswordConfirmVisible = !_vm.isPasswordConfirmVisible}},model:{value:(_vm.inputData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.inputData, "confirmPassword", $$v)},expression:"inputData.confirmPassword"}}),_c(VBtn,{staticClass:"mt-6",attrs:{"block":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" Simpan ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }